import React, { useState, useEffect } from 'react';
import { Table, Row, Col, Button, Modal, Typography, message, TableColumnsType } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ActionDropdown from '../components/ActionDropdown';
import { PumpRecord } from '../utils/types'; // Ensure the correct path to your types
import { useAuth } from '../auth/authContext'; // Import useAuth hook

const { Text, Title } = Typography;

const PumpTracking: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useAuth();
  const [dataSource, setDataSource] = useState<PumpRecord[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedPump, setSelectedPump] = useState<PumpRecord | null>(null);
  const [filterPumps, setFilterPumps] = useState<{ text: string, value: string }[]>([]);
  const [filterVendor, setFilterVendor] = useState<{ text: string, value: string }[]>([]);
  const [filterSize, setFilterSize] = useState<{ text: string, value: string }[]>([]);

  useEffect(() => {
    if (token) {
      fetchPumps();
    }
  }, [token]);

  const fetchPumps = async () => {
    try {
      const plant_id = localStorage.getItem('plant_id');
      if (!plant_id) {
        throw new Error("Plant ID is missing.");
      }
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/pumps`, {
        params: { plant_id }
      });

      const uniqueVendors = new Set<string>();
      const uniquePumps = new Set<string>();
      const uniqueSizes = new Set<string>();
      const pumps = response.data.map((pump: PumpRecord) => {
        uniquePumps.add(pump.name);
        uniqueVendors.add(pump.vendor.name);
        uniqueSizes.add(pump.parts.find((part) => part.part_type_id === 8)!.part_value);
        return {
          id: pump.id.toString(),
          name: pump.name,
          size: pump.parts.find((part) => part.part_type_id === 8)!.part_value,
          vendor: {
            id: pump.vendor.id,
            name: pump.vendor.name,
            contact_number: pump.vendor.contact_number,
            contact_email: pump.vendor.contact_email,
            company: pump.vendor.company,
          },
          parts: pump.parts,
        };
      });

      setDataSource(pumps);
      setFilterPumps(Array.from(uniquePumps).map(name => ({ text: name, value: name })));
      setFilterVendor(Array.from(uniqueVendors).map(vendor => ({ text: vendor, value: vendor })));
      setFilterSize(Array.from(uniqueSizes).map(vendor => ({ text: vendor, value: vendor })));
    } catch (error) {
      message.error('Failed to fetch pumps');
    }
  };

  const handleOnView = (record: PumpRecord) => {
    navigate(`/tracking/${record.id}`);
  };

  const handleAddPump = () => {
    navigate(`/pump/add`);
  };

  const handleEditClick = (record: PumpRecord) => {
    navigate(`/pump/edit/${record.id}`);
  };

  const handleDeleteClick = (record: PumpRecord) => {
    setSelectedPump(record);
    setIsModalVisible(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedPump) {
      try {
        await axios.delete(`/api/pumps/${selectedPump.id}`);
        message.success('Pump deleted successfully');
        setDataSource(dataSource.filter(pump => pump.id !== selectedPump.id));
      } catch (error) {
        message.error('Failed to delete pump');
      }
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns: TableColumnsType<PumpRecord> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      filters: filterPumps,
      onFilter: (value, record) => record.name.includes(value as string),
      filterMode: 'tree',
      filterSearch: true,
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      filters: filterSize,
      onFilter: (value, record) => record.vendor.name.includes(value as string),
      filterSearch: true,
    },
    {
      title: 'Vendor',
      dataIndex: ['vendor', 'name'],
      key: 'vendor',
      filters: filterVendor,
      onFilter: (value, record) => record.vendor.name.includes(value as string),
      filterSearch: true,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text: any, record: PumpRecord) => (
        <ActionDropdown record={record} onEdit={handleEditClick} onView={handleOnView} onDelete={handleDeleteClick} />
      ),
    },
  ];

  return (
    <div>
      <Title level={2} style={{ color: '#21456A', marginTop: '0px' }}>Pump Tracking Records</Title>
      <Row justify="start" style={{ marginBottom: '16px' }}>
        <Col>
          <Button type="primary" icon={<PlusOutlined />} onClick={handleAddPump} style={{ background: '#027A48' }}>
            Add Pump
          </Button>
        </Col>
      </Row>
      <Table dataSource={dataSource} columns={columns} rowKey={(record) => record.id} bordered />

      <Modal open={isModalVisible} onCancel={handleCancel} footer={null}>
        <div style={styles.modalContent}>
          <ExclamationCircleOutlined style={styles.icon} />
          <Typography.Title level={4}>Delete {selectedPump ? selectedPump.name : ''}</Typography.Title>
          <Text>This will remove all data relating to {selectedPump ? selectedPump.name : ''}. This action cannot be reversed. Deleted data cannot be recovered.</Text>
          <Row justify="space-between" style={{ marginTop: '16px' }}>
            <Col span={11}>
              <Button block onClick={handleCancel}>Cancel</Button>
            </Col>
            <Col span={11}>
              <Button block type="primary" danger onClick={handleDeleteConfirm}>Delete</Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

const styles = {
  modalContent: {
    textAlign: 'left' as const,
  },
  icon: {
    fontSize: '24px',
    color: '#FF4D4F',
    marginBottom: '16px',
  },
};

export default PumpTracking;
