import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { useAuth } from "./authContext";
import PrivateRoute from "./PrivateRoute";
import Home from '../pages/Home';
import About from '../pages/About';
import Login from '../pages/Login';
import PumpDirectory from '../pages/PumpDirectory';
import PumpDetails from '../pages/PumpDetails';
import AddPumpPage from '../pages/AddPumpPage';
import EditPumpPage from '../pages/EditPumpPage';
import PumpTracking from '../pages/PumpTracking';
import PumpTrackingDetails from '../pages/PumpTrackingDetails';
import PumpTrackingUpdateDetails from '../pages/PumpTrackingUpdateDetails';
import Maintenance from '../pages/Maintenance/MaintenancePage';
import Reports from '../pages/Reports';
import VendorPage from '../pages/Vendor/Vendor';
import NotificationTest from '../pages/NotificationTest';
import ProfilePage from '../pages/ProfilePage';
import PumpQRCodeDetails from '../pages/PumpQRCodeDetails'
import AppLayout from '../components/Layout';

const AppRoutes = () => {
  const { token } = useAuth();

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Navigate to={token ? "/home" : "/login"} />} />
        <Route path="/:unique_id/:pump_id" element={<PumpQRCodeDetails />}/>
        <Route element={<PrivateRoute />}>
          <Route path="/home" element={<AppLayout><Home /></AppLayout>} />
          <Route path="/about" element={<AppLayout><About /></AppLayout>} />
          <Route path="/pumps" element={<AppLayout><PumpDirectory /></AppLayout>} />
          <Route path="/pump/:pumpId" element={<AppLayout><PumpDetails /></AppLayout>} />
          <Route path="/pump/add" element={<AppLayout><AddPumpPage /></AppLayout>} />
          <Route path="/pump/edit/:pumpId" element={<AppLayout><EditPumpPage /></AppLayout>} />
          <Route path="/tracking" element={<AppLayout><PumpTracking /></AppLayout>} />
          <Route path="/tracking/:pumpId" element={<AppLayout><PumpTrackingDetails /></AppLayout>} />
          <Route path="/trackingUpdate/:partId" element={<AppLayout><PumpTrackingUpdateDetails /></AppLayout>} />
          <Route path="/maintenance" element={<AppLayout><Maintenance /></AppLayout>} />
          <Route path="/reports" element={<AppLayout><Reports /></AppLayout>} />
          <Route path="/vendors" element={<AppLayout><VendorPage /></AppLayout>} />
          <Route path="/note/test" element={<AppLayout><NotificationTest /></AppLayout>} />
          <Route path="/profile" element={<AppLayout><ProfilePage /></AppLayout>} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRoutes;
