import React, { useEffect, useState } from 'react';
import { Table, message, Typography, Row, Col, Button, Modal, Form, Input, Dropdown, MenuProps } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined, EllipsisOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Vendor } from '../../utils/types';
import { useAuth } from '../../auth/authContext';

const { Title } = Typography;

const ActionDropdown: React.FC<{ record: Vendor; onEdit: (record: Vendor) => void; onDelete: (record: Vendor) => void; }> = ({ record, onEdit, onDelete }) => {
  const handleMenuClick = (e: any) => {
    if (e.key === 'edit') {
      onEdit(record);
    } else if (e.key === 'delete') {
      onDelete(record);
    }
  };

  const items: MenuProps['items'] = [
    {
      key: 'edit',
      label: 'Edit',
      icon: <EditOutlined />
    },
    {
      key: 'delete',
      label: 'Delete',
      icon: <DeleteOutlined />
    }
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Dropdown menu={menuProps} placement="bottomLeft" trigger={['click']}>
      <Button shape="circle" icon={<EllipsisOutlined />} />
    </Dropdown>
  );
};

const columns = (onEdit: (record: Vendor) => void, onDelete: (record: Vendor) => void) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Number',
    dataIndex: 'contact_number',
    key: 'contact_number',
  },
  {
    title: 'Company',
    dataIndex: 'company',
    key: 'company',
  },
  {
    title: 'Actions',
    key: 'actions',
    fixed: 'right',
    render: (text: string, record: Vendor) => (
      <ActionDropdown record={record} onEdit={onEdit} onDelete={onDelete} />
    ),
  }
];

const VendorPage: React.FC = () => {
  const [data, setData] = useState<Vendor[]>([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [selectedRecord, setSelectedRecord] = useState<Vendor | null>(null);
  const { token } = useAuth(); // Get token from AuthContext

  useEffect(() => {
    if (token) {
      fetchVendors();
    }
  }, [token]);

  useEffect(() => {
    if (selectedRecord) {
      form.setFieldsValue(selectedRecord);
    } else {
      form.resetFields();
    }
  }, [selectedRecord, form]);

  const fetchVendors = async () => {
    try {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/vendors`);
      setData(response.data);
      setLoading(false);
    } catch (error) {
      message.error('Failed to fetch vendors');
      console.error('Error fetching vendors:', error);
    }
  };

  const handleAddVendor = () => {
    form.resetFields();
    setSelectedRecord(null);
    setIsModalVisible(true);
  };

  const handleEditVendor = (record: Vendor) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  const handleDeleteVendor = async (record: Vendor) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this vendor?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone',
      async onOk() {
        try {
          await axios.delete(`${process.env.REACT_APP_API_URL}/vendors/${record.id}`);
          message.success('Vendor deleted successfully');
          fetchVendors();
        } catch (error) {
          message.error('Failed to delete vendor');
          console.error('Failed to delete vendor:', error);
        }
      },
    });
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      if (selectedRecord) {
        // Update existing record
        await axios.put(`${process.env.REACT_APP_API_URL}/vendors/${selectedRecord.id}`, values);
        message.success('Vendor updated successfully');
      } else {
        // Add new record
        await axios.post(`${process.env.REACT_APP_API_URL}/vendors`, values);
        message.success('Vendor added successfully');
      }
      fetchVendors();
      setIsModalVisible(false);
    } catch (error) {
      message.error('Failed to save vendor');
      console.error('Failed to save vendor:', error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <Title level={2} style={{ color: '#21456A', marginTop: '0px' }}>Vendors</Title>
      <Row gutter={[16, 16]} justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
        <Col span={16}>
          <Button type="primary" icon={<PlusOutlined />} onClick={handleAddVendor} style={{ background: '#027A48' }}>
            Add Vendor
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns(handleEditVendor, handleDeleteVendor)}
        dataSource={data}
        loading={loading}
        rowKey={(record: Vendor) => record.id}
        bordered
      />
      <Modal
        title={selectedRecord ? 'Edit Vendor' : 'Add Vendor'}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input the name!' }]}>
            <Input placeholder="Enter vendor name" />
          </Form.Item>
          <Form.Item name="company" label="Company" rules={[{ required: true, message: 'Please input the company!' }]}>
            <Input placeholder="Enter company name" />
          </Form.Item>
          <Form.Item name="contact_number" label="Contact Number" rules={[{ required: true, message: 'Please input the contact number!' }, { pattern: /^\d+$/, message: 'Contact number must be numeric!' }]}>
            <Input placeholder="Enter contact number" />
          </Form.Item>
          <Form.Item name="contact_email" label="Contact Email" rules={[{ required: true, message: 'Please input the contact email!' }, { type: 'email', message: 'Please enter a valid email!' }]}>
            <Input placeholder="Enter contact email" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default VendorPage;
