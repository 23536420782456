import React, { useEffect, useState } from 'react';
import { Table, message, Typography, Row, Card, Button, Modal, DatePicker } from 'antd';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { FileExcelOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useAuth } from '../auth/authContext'; // Import useAuth hook
import * as XLSX from 'xlsx';
import moment from 'moment';

const { Title, Text } = Typography;

const Reports = () => {
  const { token } = useAuth(); // Get token from AuthContext
  const [dueParts, setDueParts] = useState<any[]>([]);
  const [averageTimes, setAverageTimes] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [activeTabKey, setActiveTabKey] = useState<string>('partsChanged');
  const [buttonLoading, setButtonLoading] = useState(false); // State for button loading
  const [isModalVisible, setIsModalVisible] = useState(false); // State for modal visibility
  const [selectedExportType, setSelectedExportType] = useState<string | null>(null); // Track which export button was clicked
  const [startDate, setStartDate] = useState<moment.Moment | null>(null); // State for start date
  const [endDate, setEndDate] = useState<moment.Moment | null>(null); // State for end date

  useEffect(() => {
    if (token) {
      fetchDueParts();
      fetchAverageTimes();
    }
  }, [token]);

  const fetchDueParts = async () => {
    try {
      const plant_id = localStorage.getItem('plant_id');
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/reports/due-for-replacement`, { params: { plant_id } });
      const duePartsArray = Object.values(response.data); // Convert map to array
      setDueParts(duePartsArray);
    } catch (error) {
      message.error('Failed to fetch due pump parts');
      console.error('Failed to fetch due pump parts:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAverageTimes = async () => {
    try {
      const plant_id = localStorage.getItem('plant_id');
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/reports/average-replacement-times`, { params: { plant_id } });
      if (Array.isArray(response.data)) {
        setAverageTimes(response.data);
      } else {
        throw new Error('Invalid data format');
      }
    } catch (error) {
      message.error('Failed to fetch average replacement times');
      console.error('Failed to fetch average replacement times:', error);
    }
  };

  const fetchPartsChanged = async (startDate: string, endDate: string) => {
    try {
      setButtonLoading(true); // Set button loading
      const plant_id = localStorage.getItem('plant_id');
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/reports/parts-changed`, { params: { plant_id, startDate, endDate } });
      exportToExcel(response.data, 'PartsChangedReport.xlsx');
      setButtonLoading(false); // Set button loading
    } catch (error) {
      message.error('Failed to fetch parts changed');
      console.error('Failed to fetch parts changed:', error);
    }
  };

  const fetchLongestLastingSpares = async (startDate: string, endDate: string) => {
    try {
      setButtonLoading(true); // Set button loading
      const plant_id = localStorage.getItem('plant_id');
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/reports/longest-lasting-spares`, { params: { plant_id, startDate, endDate } });
      exportToExcel(response.data, 'LongestLastingSparesReport.xlsx');
      setButtonLoading(false); // Set button loading
    } catch (error) {
      message.error('Failed to fetch longest lasting spares');
      console.error('Failed to fetch longest lasting spares:', error);
    }
  };

  const fetchAllPumpParts = async () => {
    try {
      setButtonLoading(true); 
      const plant_id = localStorage.getItem('plant_id');
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/reports/all-pump-parts`, { params: { plant_id } });
      exportToExcel(response.data, 'AllPumpPartsReport.xlsx');
      setButtonLoading(false); 
    } catch (error) {
      message.error('Failed to fetch all pump parts');
      console.error('Failed to fetch all pump parts:', error);
    }
  };

  const fetchVendorSpecificReport = async () => {
    try {
      setButtonLoading(true); 
      const plant_id = localStorage.getItem('plant_id');
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/reports/vendor-specific`, { params: { plant_id } });
      exportToExcel(response.data, 'VendorSpecificReport.xlsx');
      setButtonLoading(false); 
    } catch (error) {
      message.error('Failed to fetch vendor specific report');
      console.error('Failed to fetch vendor specific report:', error);
    }
  };

  const exportToExcel = (data: any[], fileName: string) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");
    XLSX.writeFile(workbook, fileName);
  };

  const openDateRangeModal = (exportType: string) => {
    setSelectedExportType(exportType);
    setIsModalVisible(true);
  };

  const handleExport = () => {
    if (startDate && endDate && selectedExportType) {
      const formattedStartDate = startDate.format('YYYY-MM-DD');
      const formattedEndDate = endDate.format('YYYY-MM-DD');

      switch (selectedExportType) {
        case 'partsChanged':
          fetchPartsChanged(formattedStartDate, formattedEndDate);
          break;
        case 'longestLastingSpares':
          fetchLongestLastingSpares(formattedStartDate, formattedEndDate);
          break;
        // Add more cases if needed for other export types
        default:
          break;
      }

      setIsModalVisible(false);
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setStartDate(null);
    setEndDate(null);
  };

  const onStartDateChange = (date: moment.Moment | null) => {
    setStartDate(date);
    if (date && endDate && date.isAfter(endDate)) {
      setEndDate(null); // Reset end date if start date is after the current end date
    }
  };

  const onEndDateChange = (date: moment.Moment | null) => {
    setEndDate(date);
  };

  const tabList = [
    {
      key: 'partsChanged',
      tab: 'PCR',
    },
    {
      key: 'longestLastingSpares',
      tab: 'LLSR',
    },
    {
      key: 'allPumpParts',
      tab: 'APPR',
    },
    {
      key: 'vendorSpecific',
      tab: 'VSR',
    },
  ];

  const contentList: Record<string, React.ReactNode> = {
    partsChanged: (
        <>
            <Text>Pulls a report of the parts changed based off selected date range</Text>
            <br /> <br />
            <Button type="primary" loading={buttonLoading} onClick={() => openDateRangeModal('partsChanged')}>
                Export to Excel
            </Button>
        </>
    ),
    longestLastingSpares: (
        <>
            <Text>Pulls a report of the longest lasting spares</Text>
            <br /> <br />
            <Button type="primary" loading={buttonLoading} onClick={() => openDateRangeModal('longestLastingSpares')}>
                Export to Excel
            </Button>
        </>
    ),
    allPumpParts: (
        <>
            <Text>Pulls a report of all pumps with their parts</Text>
            <br /> <br />
            <Button type="primary" loading={buttonLoading} onClick={fetchAllPumpParts}>
                Export to Excel
            </Button>
        </>
    ),
    vendorSpecific: (
        <>
            <Text>Pulls a vendor-specific report with all the pumps and parts</Text>
            <br /> <br />
            <Button type="primary" loading={buttonLoading} onClick={fetchVendorSpecificReport}>
                Export to Excel
            </Button>
        </>
    ),
  };

  const onTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  const columns = [
    {
      title: 'Part Type',
      dataIndex: 'part_type_name',
      key: 'part_type_name',
    },
    {
      title: 'Pump Name',
      dataIndex: 'pump_name',
      key: 'pump_name',
    },
    {
      title: 'Installation Date',
      dataIndex: 'installation_date',
      key: 'installation_date',
      render: (date: string) => new Date(date).toLocaleDateString(),
    },
    {
      title: 'Average Days to Replace',
      dataIndex: 'average_days_to_replace',
      key: 'average_days_to_replace',
    },
  ];

  return (
    <div>
      <Card
        style={{ width: '100%', marginTop: '20px' }}
        title="Export Reports"
        tabList={tabList}
        activeTabKey={activeTabKey}
        onTabChange={onTabChange}
      >
        {contentList[activeTabKey]}
      </Card>

      <Modal
        title="Select Date Range"
        open={isModalVisible}
        onOk={handleExport}
        onCancel={handleCancel}
      >
        <DatePicker
          value={startDate}
          onChange={onStartDateChange}
          format="YYYY-MM-DD"
          style={{ width: '100%', marginBottom: '10px' }}
          placeholder="Start Date"
        />
        <DatePicker
          value={endDate}
          onChange={onEndDateChange}
          format="YYYY-MM-DD"
          style={{ width: '100%' }}
          disabled={!startDate} // Disable the end date picker until a start date is selected
          placeholder="End Date"
        />
      </Modal>

      <Title level={2} style={{ color: '#21456A', marginTop: '20px' }}>Pump Parts Due for Replacement</Title>
      <Table
        columns={columns}
        dataSource={dueParts}
        loading={loading}
        rowKey="id"
        bordered
        style={{ marginBottom: '20px' }}
      />

      <Title level={2} style={{ color: '#21456A', marginTop: '20px' }}>Average Replacement Times</Title>
      <Card>
        <BarChart
          width={300}
          height={400}
          data={averageTimes}
          margin={{
            top: 5, right: 30, left: 0, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="part_type_name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="avg_days_to_replace" fill="#8884d8" />
        </BarChart>
      </Card>
    </div>
  );
};

export default Reports;
