import React, { useEffect, useState } from 'react';
import { Table, Button, Row, Col, Modal, Typography, message } from 'antd';
import { TableColumnsType } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { PumpRecord } from '../utils/types';
import ActionDropdown from '../components/ActionDropdown';
import { useAuth } from '../auth/authContext'; // Import useAuth hook

const { Text, Title } = Typography;

const PumpDirectory: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useAuth(); // Get token from AuthContext
  const [dataSource, setDataSource] = useState<PumpRecord[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedPump, setSelectedPump] = useState<PumpRecord | null>(null);
  const [filterPumps, setFilterPumps] = useState<{ text: string, value: string }[]>([]);
  const [filterVendor, setFilterVendor] = useState<{ text: string, value: string }[]>([]);
  const [filterApplication, setFilterApplication] = useState<{ text: string, value: string }[]>([]);

  useEffect(() => {
    if (token) {
      fetchPumps();
    }
  }, [token]);

  const fetchPumps = async () => {
    try {
      const plant_id = localStorage.getItem('plant_id');
      if (!plant_id) {
        throw new Error("Plant ID is missing.");
      }
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/pumps`, {
        params: { plant_id }
      });

      const uniqueVendors = new Set<string>();
      const uniquePumps = new Set<string>();
      const uniqueApplication = new Set<string>();
      const pumps = response.data.map((pump: PumpRecord) => {
        uniquePumps.add(pump.name);
        uniqueVendors.add(pump.vendor.name);
        uniqueApplication.add(pump.application_name);
        return {
          id: pump.id,
          key: pump.id,
          name: pump.name,
          application_name: pump.application_name,
          vendor: pump.vendor,
        };
      });

      setDataSource(pumps);
      setFilterPumps(Array.from(uniquePumps).map(name => ({ text: name, value: name })));
      setFilterVendor(Array.from(uniqueVendors).map(vendor => ({ text: vendor, value: vendor })));
      setFilterApplication(Array.from(uniqueApplication).map(pump => ({ text: pump, value: pump })));
    } catch (error) {
      message.error('Failed to fetch pumps');
    }
  };

  const handleAddPump = () => {
    navigate(`/pump/add`);
  };

  const handleEditClick = (record: PumpRecord) => {
    navigate(`/pump/edit/${record.id}`);
  };

  const handleViewClick = (record: PumpRecord) => {
    navigate(`/pump/${record.id}`);
  };

  const handleDeleteClick = (record: PumpRecord) => {
    setSelectedPump(record);
    setIsModalVisible(true);
  };

  const handleOnTracking = (record: PumpRecord) => {
    navigate(`/tracking/${record.id}`);
  };

  const handleDeleteConfirm = async () => {
    if (selectedPump) {
      try {
        await axios.delete(`/api/pumps/${selectedPump.id}`);
        message.success('Pump deleted successfully');
        setDataSource(dataSource.filter(pump => pump.id !== selectedPump.id));
      } catch (error) {
        message.error('Failed to delete pump');
      }
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns: TableColumnsType<PumpRecord> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      filters: filterPumps,
      onFilter: (value, record) => record.name.includes(value as string),
      filterMode: 'tree',
      filterSearch: true,
    },
    {
      title: 'Application Name',
      dataIndex: 'application_name',
      key: 'application_name',
      filters: filterApplication,
      onFilter: (value, record) => record.application_name.includes(value as string),
      filterSearch: true,
    },
    {
      title: 'Vendor',
      dataIndex: ['vendor', 'name'],
      key: 'vendor',
      filters: filterVendor,
      onFilter: (value, record) => record.vendor.name.includes(value as string),
      filterSearch: true,
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (_, record) => (
        <ActionDropdown record={record} onEdit={handleEditClick} onView={handleViewClick} onDelete={handleDeleteClick} onTracking={handleOnTracking} />
      ),
    },
  ];

  const selectedPumpName = selectedPump ? selectedPump.name : '';

  return (
    <div>
      <Title level={2} style={{ color: '#21456A', marginTop: '0px' }}>Pump Records</Title>
      <Row justify="start" style={{ marginBottom: '16px' }}>
        <Col>
          <Button type="primary" icon={<PlusOutlined />} onClick={handleAddPump} style={{ background: '#027A48' }}>
            Add Pump
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={dataSource}
        rowKey="id"
        scroll={{ x: 500 }}
      />
      <Modal open={isModalVisible} onCancel={handleCancel} footer={null}>
        <div style={styles.modalContent}>
          <ExclamationCircleOutlined style={styles.icon} />
          <Title level={4}>Delete {selectedPumpName}</Title>
          <Text>This will remove all data relating to {selectedPumpName}. This action cannot be reversed. Deleted data cannot be recovered.</Text>
          <Row justify="space-between" style={{ marginTop: '16px' }}>
            <Col span={11}>
              <Button block onClick={handleCancel}>Cancel</Button>
            </Col>
            <Col span={11}>
              <Button block type="primary" danger onClick={handleDeleteConfirm}>Delete</Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

const styles = {
  modalContent: {
    textAlign: 'left' as const,
  },
  icon: {
    fontSize: '24px',
    color: '#FF4D4F',
    marginBottom: '16px',
  },
};

export default PumpDirectory;
