import React, { useEffect } from 'react';
import AuthProvider from './auth/authContext';
import Routes from './auth/Routes';
import { askPermission, subscribeUser } from './utils/pushNotifications';

function App() {
  useEffect(() => {
    askPermission().then(subscribeUser);
  }, []);
  
  return (
    <AuthProvider>
      <Routes />
    </AuthProvider>
  );
}

export default App;
