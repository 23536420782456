import React, { useState } from 'react';
import { Form, Input, Button, Typography, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from '../auth/authContext';

const { Title } = Typography;

const Login: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { setToken, setUser, setPlantID } = useAuth();

    const handleFinish = async (values: { email: string; password: string }) => {
        setLoading(true);
        try {
            const deviceId = localStorage.getItem('device_id') || uuidv4();
            localStorage.setItem('device_id', deviceId);
    
            const deviceName = navigator.userAgent;
    
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
                email: values.email,
                password: values.password,
            }, {
                headers: {
                    'X-Device-ID': deviceId,
                    'X-Device-Name': deviceName,
                }
            });
    
            console.log('Login response:', response.data); // Debug log
    
            const { token, currentUser } = response.data;
            console.log('Token:', token); // Debug log
            console.log('Current User:', currentUser); // Debug log
    
            if (token && currentUser) {
                setToken(token);
                setUser(currentUser);
                if (currentUser.plants && currentUser.plants.length > 0) {
                    setPlantID(currentUser.plants[0].id);
                } else {
                    console.error('No plants found for the user');
                }
                message.success('Logged in successfully');
                navigate('/home', { replace: true });
            } else {
                console.error('Invalid response structure');
                message.error('Login failed: Invalid response structure');
            }
        } catch (error) {
            console.error('Login failed:', error);
            message.error('Login failed');
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <div style={styles.container}>
            <div style={styles.logoContainer}>
                <Title level={2} style={styles.logoText1}>PumpPro</Title>
                <Title level={4} style={styles.logoText2}>MONITOR</Title>
            </div>
            <Form onFinish={handleFinish}>
                <Form.Item name="email" rules={[{ required: true, message: 'Please input your email!' }]}>
                    <Input placeholder="Email" />
                </Form.Item>
                <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
                    <Input.Password placeholder="Password" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Login
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

const styles = {
    container: {
        maxWidth: '300px',
        margin: '0 auto',
        padding: '100px 0',
        textAlign: 'center' as const,
    },
    logoContainer: {
        marginBottom: '40px',
    },
    logoText1: {
        color: '#21456A',
        fontWeight: 'bold',
        marginBottom: 0,
    },
    logoText2: {
        color: '#21456A',
        fontWeight: 'lighter',
        marginTop: 0,
    },
    form: {
        textAlign: 'left' as const,
    },
    input: {
        borderRadius: '4px',
    },
    loginButton: {
        backgroundColor: '#21456A',
        borderColor: '#21456A',
        borderRadius: '4px',
        height: '40px',
        fontSize: '16px',
        fontWeight: 'bold',
    },
    forgotPassword: {
        display: 'block',
        marginTop: '10px',
        color: '#1890ff',
    },
};

export default Login;
