import React, { ReactNode, useState, useEffect } from 'react';
import { Layout, Menu, Input, Row, Col, Divider, Select, Typography, message, AutoComplete } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  BarsOutlined,
  SecurityScanOutlined,
  CalendarOutlined,
  BarChartOutlined,
  HomeOutlined,
  ContactsOutlined,
} from '@ant-design/icons';
import ProfileAvatar from './ProfileAvatar';
import { User, PumpRecord } from '../utils/types';
import '../css/AppLayout.css';
import { useAuth } from '../auth/authContext';
import axios from 'axios';

const { Header, Content, Footer, Sider } = Layout;
const { Option } = Select;
const { Text } = Typography;

type AppLayoutProps = {
  children: ReactNode;
};

const initialBottomNavItems = [
  {
    key: 'home',
    icon: <HomeOutlined style={{ fontSize: '20px' }} />,
    label: 'Home'
  },
  {
    key: 'pumps',
    icon: <BarsOutlined style={{ fontSize: '20px' }} />,
    label: 'Pumps'
  },
  // {
  //   key: 'tracking',
  //   icon: <SecurityScanOutlined style={{ fontSize: '20px' }} />,
  //   label: 'Tracking'
  // },
  {
    key: 'maintenance',
    icon: <CalendarOutlined style={{ fontSize: '20px' }} />,
    label: 'Upkeep'
  },
  {
    key: 'reports',
    icon: <BarChartOutlined style={{ fontSize: '20px' }} />,
    label: 'Reports'
  },
];

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const user: User = JSON.parse(localStorage.getItem('user') || '{}');
  const plantId = localStorage.getItem('plant_id');
  const { token } = useAuth(); // Get token from AuthContext
  const [selectedKey, setSelectedKey] = useState<string | null>(null);
  const [selectedPlant, setSelectedPlant] = useState<string>(
    plantId ? user.plants.find(plant => plant.id === parseInt(plantId))!.name || '' : ''
  );
  const [dataSource, setDataSource] = useState<PumpRecord[]>([]);
  const [filteredPumps, setFilteredPumps] = useState<PumpRecord[]>([]);
  const [searchOptions, setSearchOptions] = useState<{ value: string, pump: PumpRecord }[]>([]);
  const plants: string[] = user.plants.map(plant => plant.name);

  useEffect(() => {
    if (token) {
      fetchPumps();
    }
  }, [token]);

  const fetchPumps = async () => {
    try {
      const plant_id = localStorage.getItem('plant_id');
      if (!plant_id) {
        throw new Error("Plant ID is missing.");
      }
      
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/pumps`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        params: { plant_id },
      });
  
      if (response.data && response.data.length > 0) {
        const pumps = response.data.map((pump: PumpRecord) => ({
          id: pump.id,
          key: pump.id,
          name: pump.name,
          size: pump.parts.find((part) => part.part_type_id === 8)!.part_value || 'Unknown',
          vendor: pump.vendor,
        }));
  
        setDataSource(pumps);
        setFilteredPumps(pumps);
      } else {
        message.error('Failed to fetch pumps');
      }
    } catch (error) {
    }
  };
  
  useEffect(() => {
    if (plantId && user.plants) {
      const plant = user.plants.find(plant => plant.id === parseInt(plantId));
      if (plant) {
        setSelectedPlant(plant.name);
      }
    } else {
      console.warn('plantId or user.plants is missing.');
    }
  }, [plantId, user.plants]);

  const handleMenuClick = (key: string) => {
    setSelectedKey(key);
  };

  const handlePlantChange = (value: string) => {
    setSelectedPlant(value);
    const selectedPlantObj = user.plants.find(plant => plant.name === value);
    if (selectedPlantObj) {
      localStorage.setItem('plant_id', selectedPlantObj.id.toString());
      window.location.reload();
    }
  };

  const shouldShowVendors = user.roles && user.roles.some(role => [4, 5].includes(role.id));

  const menuItems = initialBottomNavItems
    .concat(
      shouldShowVendors
        ? [
          {
            key: 'vendors',
            icon: <ContactsOutlined style={{ fontSize: '20px' }} />,
            label: 'Vendors'
          },
        ]
        : []
    ).map(item => ({
      key: item.key,
      label: (
        <Link to={`/${item.key}`} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#21456A' }}>
          {item.icon}
          <div style={{ marginTop: '-10px', marginInlineStart: '0px' }}>{item.label}</div>
        </Link>
      ),
    }));

  const sidebarMenuItems = initialBottomNavItems
    .concat(
      shouldShowVendors
        ? [
          {
            key: 'vendors',
            icon: <ContactsOutlined style={{ fontSize: '20px' }} />,
            label: 'Vendors'
          },
        ]
        : []
    )
    .map(item => ({
      key: item.key,
      label: (
        <Link to={`/${item.key}`} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#fff' }}>
          {item.icon}
          <div style={{ marginInlineStart: '0px' }}>{item.label}</div>
        </Link>
      ),
    }));

  const shouldShowPlantDropdown = user.roles && user.roles.some(role => [4, 5].includes(role.id)) &&
    initialBottomNavItems.some(item => location.pathname === `/${item.key}`);

  const handleSearch = (value: string) => {
    const filtered = dataSource.filter(pump => pump.name.toLowerCase().includes(value.toLowerCase()));
    setFilteredPumps(filtered);
    setSearchOptions(filtered.map(pump => ({ value: pump.name, pump })));
  };

  const handleSelect = (value: string) => {
    const selectedPump = dataSource.find(pump => pump.name === value);
    if (selectedPump) {
      navigate(`/tracking/${selectedPump.id}`);
    }
  };

  return (
    <Layout className="layout">
      <Sider breakpoint="lg" collapsedWidth="0" width={200} style={{ backgroundColor: '#21456A', height: '100%' }}>
        <Menu
          mode="inline"
          selectedKeys={[selectedKey!]}
          onClick={(e) => handleMenuClick(e.key)}
          items={sidebarMenuItems}
          style={{ height: '100%', borderRight: 0, background: '#21456A' }}
          theme="dark"
        />
      </Sider>

      <Layout className="content">
        <Header className="header">
          <Row className="header-row" align="middle">
            <Col flex="none">
              <ProfileAvatar />
            </Col>
            <Col flex="auto" className="header-profile">
              <div style={{ color: '#21456A', fontSize: '24px', fontWeight: 'bold', marginLeft: '16px' }}>{user.name}</div>
            </Col>
          </Row>

          {shouldShowPlantDropdown && (
            <Row className="plant-select">
              <Col span={24}>
                <Select
                  style={{ width: '100%', }}
                  value={selectedPlant}
                  onChange={handlePlantChange}
                  placeholder="Select Plant"
                >
                  {plants.map(plant => (
                    <Option key={plant} value={plant}>
                      {plant}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          )}

          <Row className="search-row">
            <Col span={24}>
              <AutoComplete
                style={{ width: '100%' }}
                options={searchOptions}
                onSearch={handleSearch}
                onSelect={handleSelect}
                placeholder="Search for Pump"
                allowClear
              >
              </AutoComplete>
            </Col>
          </Row>

          <Divider plain></Divider>
        </Header>

        <Content className="layout-content" style={{ paddingTop: '0px', padding: '20px', paddingBottom: '80px', background: 'white' }}>
          <div className="site-layout-content">
            {children}
          </div>
        </Content>
        <Footer className="footer">
          <Menu
            mode="horizontal"
            selectedKeys={[selectedKey!]}
            onClick={(e) => handleMenuClick(e.key)}
            items={menuItems}
            style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', color: '#21456A', margin: '0 auto', height: '100%' }}
          />
        </Footer>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
