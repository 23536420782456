import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Switch, Button, message, Row, Col, Typography, Divider, Upload } from 'antd';
import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { PumpFormValues, Vendor } from '../utils/types';
import { useNavigate } from 'react-router-dom';
import { saveData } from '../utils/indexedDB';
import { useAuth } from '../auth/authContext'; // Import useAuth hook

const { Option } = Select;
const { Title } = Typography;

const AddPumpPage: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { token } = useAuth(); // Get token from AuthContext
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [fileList, setFileList] = useState<any[]>([]); // State to manage uploaded files
  const [loading, setLoadings] = useState<boolean>(false);

  useEffect(() => {
    if (token) {
      fetchVendors();
    }
  }, [token]);

  const fetchVendors = async () => {
    try {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/vendors`);
      setVendors(response.data);
    } catch (error) {
      message.error('Failed to fetch vendors');
      console.error('Error fetching vendors:', error);
    }
  };

  const saveDataToServer = async (values: PumpFormValues) => {
    const parts = [
      { part_type_name: 'Motor Size', part_value: values.motor_size },
      { part_type_name: 'Pully for motor', part_value: values.pully_for_motor },
      { part_type_name: 'Pully for pump', part_value: values.pully_for_pump },
      { part_type_name: 'Pump speed', part_value: values.pump_speed },
      { part_type_name: 'VSD controlled', part_value: values.vsd_controlled ? 'Yes' : 'No' },
      { part_type_name: 'Ceiling', part_value: values.ceiling },
      { part_type_name: 'Bearing Size', part_value: values.bearing_size },
      { part_type_name: 'Pump Size', part_value: values.pump_size },
      { part_type_name: 'Pump Serial number', part_value: values.pump_serial_number },
      { part_type_name: 'Impella', part_value: values.impella },
      { part_type_name: 'Liners', part_value: values.liners },
      { part_type_name: 'Volute', part_value: values.volute },
      { part_type_name: 'Throat bush', part_value: values.throat_bush },
      { part_type_name: 'Packing', part_value: values.packing },
      { part_type_name: 'Shaft sleeve', part_value: values.shaft_sleeve },
    ];
  
    const plant_id = localStorage.getItem('plant_id');
    if (!plant_id) {
      throw new Error("Plant ID is missing.");
    }
    values.plant_id = parseInt(plant_id);
  
    const formData = new FormData();
  
    formData.append('plant_id', values.plant_id.toString());
    formData.append('name', values.name);
    formData.append('application_name', values.application_name || '');
    formData.append('vendor_id', values.vendor_id ? values.vendor_id.toString() : '');
  
    // Append each part individually to FormData
    parts.forEach((part, index) => {
      formData.append(`parts[${index}][part_type_name]`, part.part_type_name);
      formData.append(`parts[${index}][part_value]`, part.part_value);
    });
  
    // Handle file upload if a file was provided
    if (fileList.length > 0) {
      const file = fileList[0].originFileObj || fileList[0]; // Get the actual file object
  
      // Log the file type to ensure it's correct
      console.log('File type:', file.type);
      console.log('File object:', file);
  
      if (file instanceof File && ['application/pdf', 'image/jpeg', 'image/png'].includes(file.type)) {
        formData.append('file', file);
      } else {
        message.error('Invalid file type. Please upload a PDF, JPG, PNG, or JPEG file.');
        return;
      }

      formData.forEach((value, key) => {
        console.log(`${key}:`, value);
      });
    }
  
    try {
      setLoadings(true);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      await axios.post(`${process.env.REACT_APP_API_URL}/pumps`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      message.success('Pump added successfully');
      form.resetFields(); // Clear the form
      setFileList([]); // Clear the file list
      navigate('/pumps');
      setLoadings(false);
    } catch (error) {
      setLoadings(false);
      message.error('Failed to add pump');
      console.error('Error adding pump:', error);
    }
  };
  
  
  

  const saveDataToIndexedDB = async (values: PumpFormValues) => {
    try {
      await saveData(values);
      message.success('Pump saved locally. It will be submitted when online.');
    } catch (error) {
      message.error('Failed to save pump locally');
      console.error('Error saving pump locally:', error);
    }
  };

  const onFinish = async (values: PumpFormValues) => {
    console.log('Received values of form: ', values);

    if (navigator.onLine) {
      await saveDataToServer(values);
    } else {
      await saveDataToIndexedDB(values);
    }
  };

  const handleCancel = () => {
    navigate(-1); // Navigate back to the previous page
  };

  // Handle file change
  const handleFileChange = ({ fileList }: any) => setFileList(fileList);

  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col>
          <Title level={3} style={{ color: '#21456A', fontWeight: 'bold', marginTop: '4px' }}>Add New Pump</Title>
        </Col>
        <Col>
          <Button shape="circle" icon={<CloseOutlined />} style={{ border: 'none', color: '#21456A', boxShadow: 'none' }} onClick={handleCancel} />
        </Col>
      </Row>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Divider>Application Data</Divider>
        <Form.Item label="Pump Name" name="name" rules={[{ required: true, message: 'Please enter pump name' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Application Name" name="application_name" rules={[{ required: true, message: 'Please enter application name' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Vendor" name="vendor_id" rules={[{ required: true, message: 'Please select a vendor' }]}>
          <Select>
            {vendors.map(vendor => (
              <Option key={vendor.id} value={vendor.id}>
                {vendor.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="VSD Controlled" name="vsd_controlled" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item label="Motor Size" name="motor_size" rules={[{ required: true, message: 'Please enter motor size' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Pully for Motor" name="pully_for_motor" rules={[{ required: true, message: 'Please enter pully for motor' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Pully for Pump" name="pully_for_pump" rules={[{ required: true, message: 'Please enter pully for pump' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Pump Speed" name="pump_speed" rules={[{ required: true, message: 'Please enter pump speed' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Ceiling" name="ceiling" rules={[{ required: true, message: 'Please enter ceiling' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Bearing Size" name="bearing_size" rules={[{ required: true, message: 'Please enter bearing size' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Pump Size" name="pump_size" rules={[{ required: true, message: 'Please enter pump size' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Pump Serial Number" name="pump_serial_number" rules={[{ required: true, message: 'Please enter pump serial number' }]}>
          <Input />
        </Form.Item>

        {/* File Upload */}
        <Form.Item label="Upload Pump File" name="file">
          <Upload
            beforeUpload={() => false} // Prevent auto upload, we'll handle it manually
            fileList={fileList}
            onChange={handleFileChange}
            accept='application/pdf, image/jpeg, image/jpg, image/png'
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>

        <Divider>Wearable Data</Divider>
        <Form.Item label="Impella" name="impella" rules={[{ required: true, message: 'Please select impella' }]}>
          <Select>
            <Option value="metal">Metal Impella</Option>
            <Option value="polyurithian">Polyurithian Impella</Option>
            <Option value="rubber">Rubber Impella</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Liners" name="liners" rules={[{ required: true, message: 'Please select liners' }]}>
          <Select>
            <Option value="metal">Metal Liners</Option>
            <Option value="polyurithian">Polyurithian Liners</Option>
            <Option value="rubber">Rubber Liners</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Volute" name="volute" rules={[{ required: true, message: 'Please select volute' }]}>
          <Select>
            <Option value="metal">Metal Volute</Option>
            <Option value="polyurithian">Polyurithian Volute</Option>
            <Option value="rubber">Rubber Volute</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Throat Bush" name="throat_bush" rules={[{ required: true, message: 'Please select throat bush' }]}>
          <Select>
            <Option value="metal">Metal Throat Bush</Option>
            <Option value="polyurithian">Polyurithian Throat Bush</Option>
            <Option value="rubber">Rubber Throat Bush</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Packing" name="packing" rules={[{ required: true, message: 'Please enter packing' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Shaft Sleeve" name="shaft_sleeve" rules={[{ required: true, message: 'Please select shaft sleeve' }]}>
          <Select>
            <Option value="stainless">Stainless Shaft Sleeve</Option>
            <Option value="tungsten">Tungsten Shaft Sleeve</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Button key="back" type="link" onClick={handleCancel} style={{ borderColor: '#2196F3', marginRight: '12px' }} size='large' block>
                Cancel
              </Button>
            </Col>
            <Col span={12}>
              <Button type="primary" htmlType="submit" style={{ background: '#21456A' }} size='large' block loading={loading}>
                Add Pump
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddPumpPage;

