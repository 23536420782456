import React, { useState } from 'react';
import axios from 'axios';

const NotificationTest: React.FC = () => {
  const [subscription, setSubscription] = useState<PushSubscription | null>(null);

  const subscribeToPush = async () => {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      const registration = await navigator.serviceWorker.ready;
      try {
        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlB64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY!)
        });
        setSubscription(subscription);
        await axios.post('/api/subscribe', subscription);
        alert('Subscribed successfully!');
      } catch (error) {
        console.error('Subscription failed:', error);
      }
    }
  };

  const sendTestNotification = async () => {
    try {
      await axios.post('/api/sendNotification');
      alert('Notification sent!');
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };

  const urlB64ToUint8Array = (base64String: string): Uint8Array => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  };

  return (
    <div>
      <button onClick={subscribeToPush}>Subscribe to Push Notifications</button>
      <button onClick={sendTestNotification} disabled={!subscription}>Send Test Notification</button>
    </div>
  );
};

export default NotificationTest;
