import React, { useState, useEffect } from 'react';
import { Card, Form, Switch, Button, List, Typography, message } from 'antd';
import axios from 'axios';
import { useAuth } from '../auth/authContext'; // Import useAuth hook
import { User } from '../utils/types';

const { Title } = Typography;

const ProfilePage: React.FC = () => {
    const [form] = Form.useForm();
    const { token } = useAuth();
    const user: User = JSON.parse(localStorage.getItem('user') || '{}');
    const [devices, setDevices] = useState<any[]>(user.devices || []);
    const [loading, setLoading] = useState(false);

    // This useEffect should only run once, or when the user changes (which is unlikely in a profile page).
    useEffect(() => {
        // Only set devices if it's not already set (to avoid triggering re-renders).
        if (devices.length === 0 && user.devices) {
            setDevices(user.devices);
        }
    }, [user.devices, devices.length]);

    const handleFinish = async (values: any) => {
        setLoading(true);
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            await axios.post(`${process.env.REACT_APP_API_URL}/user/${user.id}/update-notifications`, values);
            message.success('Notifications updated successfully');
            
            // Update local storage
            const updatedUser = { ...user, email_notification: values.email_notification };
            localStorage.setItem('user', JSON.stringify(updatedUser));

        } catch (error) {
            message.error('Failed to update notifications');
            console.error('Failed to update notifications:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={styles.container}>
            <Card title="User Profile" bordered={false}>
                {user && (
                    <>
                        <Title level={4}>{user.name}</Title>
                        <p>Email: {user.email}</p>
                    </>
                )}
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{ email_notification: user.email_notification }}
                    onFinish={handleFinish}
                >
                    <Form.Item
                        label="Email Notifications"
                        name="email_notification"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Update Notifications
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <Card title="Devices" bordered={false} style={{ marginTop: '20px' }}>
                <List
                    itemLayout="horizontal"
                    dataSource={devices}
                    renderItem={device => (
                        <List.Item>
                            <List.Item.Meta
                                title={device.device_name}
                                description={`Last login: ${new Date(device.last_login_at).toLocaleString()}`}
                            />
                        </List.Item>
                    )}
                />
            </Card>
        </div>
    );
};

const styles = {
    container: {
        maxWidth: '600px',
        margin: '0 auto',
        padding: '20px',
    },
};

export default ProfilePage;
