import React, { useState, useEffect } from 'react';
import { Typography, Row, Col, Card, Button, Table, Modal, Form, Input, Select, DatePicker, message, TableColumnsType } from 'antd';
import { ArrowRightOutlined, CloseOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import moment from 'moment';
import { PumpPartTrackingDetails, PumpPartTracking, TrackingRecord } from '../utils/types'; // Ensure the correct path to your types
import { useAuth } from '../auth/authContext';

const { Title, Text } = Typography;
const { Option } = Select;



const formatDate = (date: Date) => {
  return moment(date).format('YYYY-MM-DD');
};

const formatDaysFromNow = (startDate: Date, endDate: Date) => {
  const start = moment(startDate);
  const end = moment(endDate);
  const duration = moment.duration(end.diff(start));
  const days = duration.asDays();
  return `${Math.round(days)} days`;
};

const PumpTrackingUpdateDetails: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useAuth();
  const { partId } = useParams<{ partId: string }>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [chartData, setChartData] = useState<any[]>([]);
  const [trackingDetails, setTrackingDetails] = useState<PumpPartTrackingDetails>();
  const [loading, setLoading] = useState(true);
  const [selectedRecord, setSelectedRecord] = useState<TrackingRecord>();

  useEffect(() => {
    if(token){
      fetchTrackingDetails();
    }
  }, [token]);

  const fetchTrackingDetails = async () => {
    try {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/pump-part/${partId}`);
      console.log(response.data);
      const details: PumpPartTrackingDetails = response.data;

      setTrackingDetails(details);

      // Sort details.tracking by installation_date in descending order
      const sortedTrackingDesc = details.trackings.sort((a, b) => b.id - a.id);
      const newDataSource = sortedTrackingDesc.map((tracking: PumpPartTracking, index: number) => {
        const previousTracking = sortedTrackingDesc[index + 1];
        const replacedIn = previousTracking
          ? formatDaysFromNow(previousTracking.installation_date, tracking.installation_date)
          : 'N/A';

        return {
          key: tracking.id,
          pump: details.pump, // Assuming there's a user_name field
          installedDate: formatDate(tracking.installation_date),
          reason_for_update: tracking.reason_for_update,
          job_card_reference: tracking.job_card_reference,
          installation_date: tracking.installation_date,
          replacedIn,
          
        };
      });

      setDataSource(newDataSource);

      // Sort details.tracking by installation_date in ascending order
      const sortedTrackingAsc = details.trackings.sort((a, b) => a.id - b.id);

      const newChartData = sortedTrackingAsc.map((tracking: PumpPartTracking, index: number) => {
        if (index === 0) {
          // For the first entry, there's no previous entry to compare with
          return {
            name: `Entry ${index + 1}`,
            days: 0, // Or any other value that makes sense
          };
        }

        const previousTracking = sortedTrackingAsc[index - 1];
        const days = moment(tracking.installation_date).diff(moment(previousTracking.installation_date), 'days');

        return {
          name: `Entry ${index + 1}`,
          days: days,
        };
      });

      setChartData(newChartData);
    } catch (error) {
      message.error('Failed to fetch tracking details');
      console.error('Failed to fetch tracking details:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleBackClick = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setSelectedRecord(undefined);
  };

  const showEditModal = (record: TrackingRecord) => {
    setSelectedRecord(record);
    form.setFieldsValue({
      reason_for_update: record.reason_for_update,
      installationDate: moment(record.installation_date, 'YYYY-MM-DD'),
      job_card_reference: record.job_card_reference,
    });
    setIsModalVisible(true);
  };

  const columns: TableColumnsType<TrackingRecord>  = [
    {
      title: 'Vendor',
      dataIndex: ['pump', 'vendor', 'name'],
      key: 'vendor',
    },
    {
      title: 'Installed',
      dataIndex: 'installedDate',
      key: 'installedDate',
    },
    {
      title: 'Replaced in',
      dataIndex: 'replacedIn',
      key: 'replacedIn',
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      render: (_, record) => (
        <Button type="link" onClick={() => showEditModal(record)}>
          Edit
        </Button>
      ),
    },
  ];

  const handleUpdate = async (values) => {
    try {
      console.log(values);
      const apiUrl = `${process.env.REACT_APP_API_URL}/pump-part${selectedRecord ? `/${selectedRecord.key}/updateTrackingRecord` : ''}`;
      const method = selectedRecord ? 'put' : 'post';
  
      // Define requestData before the conditional block
      let requestData = {
        part_id: partId,
        reason_for_update: values.reason_for_update,
        installation_date: values.installationDate.format('YYYY-MM-DD'),
        job_card_reference: values.job_card_reference,
        vendor_id: null
      };
  
      // Add vendor_id if it's a new record
      if (!selectedRecord) {
        requestData.vendor_id = values.pump.vendor.id;
      }
  
      await axios[method](apiUrl, requestData);
  
      message.success(`${selectedRecord ? 'Updated' : 'Created'} successfully`);
      fetchTrackingDetails();
      form.resetFields();
      setIsModalVisible(false);
      setSelectedRecord(undefined);
    } catch (error) {
      message.error('Failed to update');
      console.error('Failed to update:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!trackingDetails) {
    return <div>No tracking details found.</div>;
  }

  return (
    <div style={styles.container}>
      <Title level={2} style={{ color: '#21456A', marginTop: '0px' }}>Pump Tracking Part</Title>
      <Row justify="space-between" align="middle">
        <Col>
          <Title level={3} style={{ color: '#21456A', fontWeight: 'bold' }}>{`${trackingDetails.pump.name}/${trackingDetails.part_type.name}`}</Title>
        </Col>
        <Col>
          <Button shape="circle" icon={<CloseOutlined />} onClick={handleBackClick} />
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        bordered
        style={styles.table}
      />

      <Card>
        <Title level={5}>Avg { trackingDetails.part_tracking_report ? trackingDetails.part_tracking_report.average_days_to_replace : 'N/A' } Days</Title>
        <Text>This is a chart showing the average amount of days a {trackingDetails.part_type.name} lasts</Text>
        <LineChart
          width={300}
          height={200}
          data={chartData}
          margin={{
            top: 5, right: 30, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="days" stroke="#8884d8" activeDot={{ r: 8 }} />
        </LineChart>
      </Card>

      <Row justify="space-between" style={{ marginTop: '16px' }}>
        <Col span={11}>
          <Button block onClick={handleBackClick} style={{ borderColor: '#2196F3' }}>Back</Button>
        </Col>
        <Col span={11}>
          <Button block type="primary" style={{ background: '#21456A' }} onClick={showModal}>Update</Button>
        </Col>
      </Row>

      <Modal
        title={`Update ${trackingDetails.pump.name}/${trackingDetails.part_type.name}`}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleUpdate}>
          <Form.Item label="Reason for update" name="reason_for_update" rules={[{ required: true, message: 'Please select a reason for update' }]}>
            <Select>
              <Option value="Initial creation">Initial Creation</Option>
              <Option value="Precaution Replacement">Precaution Replacement</Option>
              <Option value="Maintenance">Maintenance</Option>
              <Option value="Routine Replacement">Routine Replacement</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Installation date" name="installationDate" rules={[{ required: true, message: 'Please enter installation date' }]}>
            <DatePicker format='YYYY-MM-DD'/>
          </Form.Item>
          <Form.Item label="Jobcard reference (Optional)" name="job_card_reference">
            <Input />
          </Form.Item>
          <Row justify="space-between">
            <Col span={11}>
              <Button block onClick={handleCancel} style={{ borderColor: '#2196F3' }}>Cancel</Button>
            </Col>
            <Col span={11}>
              <Button block type="primary" htmlType="submit" style={{ background: '#21456A' }}>Update</Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

const styles = {
  container: {
    padding: '16px',
  },
  table: {
    marginBottom: '16px',
  },
  card: {
    marginTop: '16px',
    borderRadius: '8px',
    textAlign: 'center',
  },
};

export default PumpTrackingUpdateDetails;
