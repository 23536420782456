import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Descriptions, Divider, message, Row, Col, Typography, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import axios from 'axios';
import { PumpRecord, PumpPart } from '../utils/types'; // Ensure the correct path to your types
import { useAuth } from '../auth/authContext'; // Import useAuth hook

const { Title } = Typography;

const PumpDetails: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useAuth(); // Get token from AuthContext
  const { pumpId } = useParams<{ pumpId: string }>();
  const [pumpDetails, setPumpDetails] = useState<PumpRecord | null>(null);
  const [parts, setParts] = useState<{ [key: string]: string }>({});
  const [fileUrl, setFileUrl] = useState<string | null>(null); // To store the file URL
  const [fileQRCodeUrl, setFileQRCodeUrl] = useState<string | null>(null); 

  useEffect(() => {
    const fetchPumpDetails = async () => {
      try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/pumps/${pumpId}`);
        console.log(response.data);
        
        const pumpData = response.data;

        // Fetch part types to map part_type_id to part_type_name
        const partTypesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/part-types`);
        const partTypes = partTypesResponse.data;

        // Map the parts to their respective values
        const mappedParts = pumpData.parts.reduce((acc: { [key: string]: string }, part: PumpPart) => {
          const partType = partTypes.find((type: any) => type.id === part.part_type_id);
          if (partType) {
            acc[partType.name] = part.part_value;
          }
          return acc;
        }, {});

        setPumpDetails({
          id: pumpData.id.toString(),
          name: pumpData.name,
          application_name: pumpData.application_name,
          date: pumpData.created_at,
          vendor: pumpData.vendor,
          parts: pumpData.parts,
          plant_id: pumpData.plant_id,
          file_path: pumpData.file_path,
          qr_code: pumpData.qr_code
        });
        setParts(mappedParts);

        // If the pump has an associated file, set the file URL
        if (pumpData.file_path) {
          const url = `${process.env.REACT_APP_API_URL}/pumps/${pumpId}/download`;
          setFileUrl(url);
        }
        if (pumpData.qr_code) {
          const url = `${process.env.REACT_APP_API_URL}/pumps/${pumpId}/download-qr-code`;
          setFileQRCodeUrl(url);
        }
      } catch (error) {
        console.error('Failed to fetch pump details:', error);
        message.error('Failed to fetch pump details');
      }
    };

    fetchPumpDetails();
  }, [pumpId]);

  const handleCancel = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const handleDownload = async () => {
    if (fileUrl) {
      try {
        const response = await axios.get(fileUrl, {
          responseType: 'blob', // Important to specify that we're expecting a binary response (like a file)
          headers: {
            'Authorization': `Bearer ${token}`, // Include the Bearer token
          },
        });
  
        // Create a link to download the file
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', pumpDetails!.name + '.pdf'); // Use the pump name as the file name
        document.body.appendChild(link);
        link.click();
  
        // Clean up and remove the link
        link.parentNode!.removeChild(link);
      } catch (error) {
        console.error('Failed to download file:', error);
        message.error('Failed to download file');
      }
    }
  };

  const handleQRDownload = async () => {
    if (fileQRCodeUrl) {
      try {
        const response = await axios.get(fileQRCodeUrl, {
          responseType: 'blob', // Important to specify that we're expecting a binary response (like a file)
          headers: {
            'Authorization': `Bearer ${token}`, // Include the Bearer token
          },
        });
  
        // Create a link to download the file
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', pumpDetails!.name + '.png'); // Use the pump name as the file name
        document.body.appendChild(link);
        link.click();
  
        // Clean up and remove the link
        link.parentNode!.removeChild(link);
      } catch (error) {
        console.error('Failed to download file:', error);
        message.error('Failed to download file');
      }
    }
  };

  if (!pumpDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col>
          <Title level={3} style={{ color: '#21456A', fontWeight: 'bold', marginTop: '4px' }}>View - {pumpDetails.name}</Title>
        </Col>
        <Col>
          <Button shape="circle" icon={<CloseOutlined />} style={{ border: 'none', color: '#21456A', boxShadow: 'none' }} onClick={handleCancel} />
        </Col>
      </Row>
      
      {fileUrl && (
        <Button onClick={handleDownload} style={{ marginBottom: '20px' }}>
          Download PDF
        </Button>
      )}

    {fileQRCodeUrl && (
        <Button onClick={handleQRDownload} style={{ marginBottom: '20px' }}>
          Download QR Code
        </Button>
      )}
      
      <Descriptions bordered>
        <Descriptions.Item label="Pump Name">{pumpDetails.name}</Descriptions.Item>
        <Descriptions.Item label="Application Name">{pumpDetails.application_name}</Descriptions.Item>
        <Descriptions.Item label="Motor Size">{parts['Motor Size']}</Descriptions.Item>
        <Descriptions.Item label="Pully for Motor">{parts['Pully for motor']}</Descriptions.Item>
        <Descriptions.Item label="Pully for Pump">{parts['Pully for pump']}</Descriptions.Item>
        <Descriptions.Item label="Pump Speed">{parts['Pump speed']}</Descriptions.Item>
        <Descriptions.Item label="VSD Controlled">{parts['VSD controlled'] === 'Yes' ? 'Yes' : 'No'}</Descriptions.Item>
        <Descriptions.Item label="Ceiling">{parts['Ceiling']}</Descriptions.Item>
        <Descriptions.Item label="Bearing Size">{parts['Bearing Size']}</Descriptions.Item>
        <Descriptions.Item label="Pump Size">{parts['Pump Size']}</Descriptions.Item>
        <Descriptions.Item label="Pump Serial Number">{parts['Pump Serial number']}</Descriptions.Item>
      </Descriptions>

      <Divider>Pump Internals</Divider>

      <Descriptions bordered>
        <Descriptions.Item label="Impella">{parts['Impella']}</Descriptions.Item>
        <Descriptions.Item label="Liners">{parts['Liners']}</Descriptions.Item>
        <Descriptions.Item label="Volute">{parts['Volute']}</Descriptions.Item>
        <Descriptions.Item label="Throat Bush">{parts['Throat bush']}</Descriptions.Item>
        <Descriptions.Item label="Packing">{parts['Packing']}</Descriptions.Item>
        <Descriptions.Item label="Shaft Sleeve">{parts['Shaft sleeve']}</Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default PumpDetails;
