import React from 'react';
import { Dropdown, Button, MenuProps,} from 'antd';
import { EllipsisOutlined, EyeOutlined, PhoneOutlined, DeleteOutlined, EditOutlined, SecurityScanOutlined } from '@ant-design/icons';

interface ActionDropdownProps {
    record: {
        id: string;
        name: string;
        application_name: string;
        date: string;
    };
    onEdit: (record: any) => void;
    onView: (record: any) => void;
    onDelete: (record: any) => void;
    onTracking: (record: any) => void;
}

const ActionDropdown: React.FC<ActionDropdownProps> = ({ record, onEdit, onView, onDelete, onTracking}) => {
    const handleMenuClick = (e: any) => {
      console.log('click happened')
      if (e.key === 'edit') {
        onEdit(record);
      } else if (e.key === 'delete') {
        // Handle delete action here
        onDelete(record)
        console.log('Delete action for record:', record);
      } else if (e.key === 'view') {
        // Handle view action here
        onView(record);
        console.log('View action for record:', record);
      }else if (e.key === 'tracking'){
        onTracking(record);
        console.log('Tracking action for record:', record);
      }
    };

  const items: MenuProps['items'] = [
    {
      key: 'view',
      label: 'View',
      icon: <EyeOutlined />
    },
    {
      key: 'tracking',
      label: 'Tracking',
      icon: <SecurityScanOutlined />
    },
    {
      key: 'edit',
      label: 'Edit',
      icon: <EditOutlined />
    },
    {
      key: 'delete',
      label: 'Delete',
      icon: <DeleteOutlined />
    },
    {
      key: 'contact',
      label: 'Contact',
      icon: <PhoneOutlined />
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Dropdown menu={menuProps} placement="bottomLeft" trigger={['click']}>
      <Button shape="circle" icon={<EllipsisOutlined />} />
    </Dropdown>
  );
};

export default ActionDropdown;
