import React, { useEffect, useState } from 'react';
import { Table, message, Typography, Row, Col, Select, Button, Modal, Form, Input, Dropdown, MenuProps, DatePicker } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined, EllipsisOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import dayjs from 'dayjs';
import { MaintenanceRecord } from '../../utils/types';

const { Option } = Select;
const { Title } = Typography;

const ActionDropdown: React.FC<{ record: MaintenanceRecord; onEdit: (record: MaintenanceRecord) => void; onDelete: (record: MaintenanceRecord) => void; }> = ({ record, onEdit, onDelete }) => {
  const handleMenuClick = (e: any) => {
    if (e.key === 'edit') {
      onEdit(record);
    } else if (e.key === 'delete') {
      onDelete(record);
    }
  };

  const items: MenuProps['items'] = [
    {
      key: 'edit',
      label: 'Edit',
      icon: <EditOutlined />
    },
    {
      key: 'delete',
      label: 'Delete',
      icon: <DeleteOutlined />
    }
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Dropdown menu={menuProps} placement="bottomLeft" trigger={['click']}>
      <Button shape="circle" icon={<EllipsisOutlined />} />
    </Dropdown>
  );
};

const columns = (onEdit: (record: MaintenanceRecord) => void, onDelete: (record: MaintenanceRecord) => void) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Maintenance Day',
    dataIndex: 'maintenance_day',
    key: 'maintenance_day',
    render: (text: string) => new Date(text).toLocaleDateString(),
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (text: string, record: MaintenanceRecord) => (
      <ActionDropdown record={record} onEdit={onEdit} onDelete={onDelete} />
    ),
  }
];

const Maintenance: React.FC = () => {
  const [data, setData] = useState<MaintenanceRecord[]>([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [selectedRecord, setSelectedRecord] = useState<MaintenanceRecord | null>(null);
  const plant_id = localStorage.getItem('plant_id');

  useEffect(() => {
    fetchMaintenanceRecords();
  }, []);

  useEffect(() => {
    if (selectedRecord) {
      form.setFieldsValue({
        ...selectedRecord,
        maintenance_day: dayjs(selectedRecord.maintenance_day, 'YYYY-MM-DD'), // Ensure correct date format for input
      });
    } else {
      form.resetFields();
    }
  }, [selectedRecord, form]);

  const fetchMaintenanceRecords = async () => {
    try {
      const plant_id = localStorage.getItem('plant_id');
      if (!plant_id) {
        throw new Error("Plant ID is missing.");
      }
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/maintenances`, {
        params: { plant_id }
      });
      setData(response.data);
      setLoading(false);
    } catch (error) {
      message.error('Failed to fetch maintenance records');
      console.error('Failed to fetch maintenance records:', error);
    }
  };

  const handleAddMaintenanceDay = () => {
    form.resetFields();
    setSelectedRecord(null);
    setIsModalVisible(true);
  };

  const handleEditMaintenance = (record: MaintenanceRecord) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  const handleDeleteMaintenance = async (record: MaintenanceRecord) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this maintenance record?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone',
      async onOk() {
        try {
          await axios.delete(`${process.env.REACT_APP_API_URL}/maintenances/${record.id}`);
          message.success('Maintenance record deleted successfully');
          fetchMaintenanceRecords();
        } catch (error) {
          message.error('Failed to delete maintenance record');
          console.error('Failed to delete maintenance record:', error);
        }
      },
    });
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      values.maintenance_day = values.maintenance_day.format('YYYY-MM-DD'); // Ensure correct date format
      if (selectedRecord) {
        // Update existing record
        await axios.put(`${process.env.REACT_APP_API_URL}/maintenances/${selectedRecord.id}`, { ...values, plant_id });
        message.success('Maintenance record updated successfully');
      } else {
        // Add new record
        await axios.post(`${process.env.REACT_APP_API_URL}/maintenances`, { ...values, plant_id });
        message.success('Maintenance record added successfully');
      }
      fetchMaintenanceRecords();
      setIsModalVisible(false);
    } catch (error) {
      message.error('Failed to save maintenance record');
      console.error('Failed to save maintenance record:', error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSortChange = (value: string) => {
    console.log(`Sort by: ${value}`);
    // Add sorting logic here
  };

  return (
    <div>
      <Title level={2} style={{ color: '#21456A', marginTop: '0px' }}>Maintenance Records</Title>
      <Row gutter={[16, 16]} justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
        <Col span={8}>
          <Select defaultValue="Sort by" style={{ width: 120 }} onChange={handleSortChange}>
            <Option value="name">Name</Option>
            <Option value="vendor">Vendor</Option>
          </Select>
        </Col>
        <Col span={16}>
          <Button type="primary" icon={<PlusOutlined />} onClick={handleAddMaintenanceDay} style={{ background: '#027A48' }}>
            Add Maintenance Day
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns(handleEditMaintenance, handleDeleteMaintenance)}
        dataSource={data}
        loading={loading}
        rowKey={(record: MaintenanceRecord) => record.id}
        bordered
      />
      <Modal
        title={selectedRecord ? 'Edit Maintenance' : 'Add Maintenance'}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input the name!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="maintenance_day" label="Maintenance Day" rules={[{ required: true, message: 'Please input the maintenance day!' }]}>
            <DatePicker format='YYYY-MM-DD'/>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Maintenance;
