// src/utils/indexedDB.ts
import { openDB } from 'idb';

const DB_NAME = 'PumpDatabase';
const DB_VERSION = 1;
const STORE_NAME = 'pumps';

const initDB = async () => {
  return openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: 'id', autoIncrement: true });
      }
    },
  });
};

export const saveData = async (data: any) => {
  const db = await initDB();
  await db.add(STORE_NAME, data);
};

export const getAllData = async () => {
  const db = await initDB();
  return await db.getAll(STORE_NAME);
};

export const clearData = async () => {
  const db = await initDB();
  await db.clear(STORE_NAME);
};
