import React from 'react';
import { Avatar, Dropdown, Menu, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/authContext';

const ProfileAvatar: React.FC = () => {
    const { setToken, setPlantID, setUser } = useAuth();
    const navigate = useNavigate();

    const handleMenuClick = (e: any) => {
        if (e.key === 'profile') {
            navigate('/profile');
        } else if (e.key === 'logout') {
            setToken(null);
            setPlantID(null);
            setUser(null);
            message.success('Logged out successfully');
            navigate('/login', { replace: true });
        }
    };

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="profile">
                Profile
            </Menu.Item>
            <Menu.Item key="logout">
                Logout
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <Avatar size={32} icon={<UserOutlined />} style={{ backgroundColor: '#21456A', marginRight: '12px' }} />
        </Dropdown>
    );
};

export default ProfileAvatar;
