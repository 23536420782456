import React, { useState, useEffect } from 'react';
import { List, Typography, Spin } from 'antd';
import NotificationItem from '../components/NotificationItem';
import { ExclamationCircleFilled, WarningFilled, ReloadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Notification } from '../utils/types'; // Adjust the import path as necessary
import moment from 'moment';
import { useAuth } from '../auth/authContext';

const { Title } = Typography;

const Home: React.FC = () => {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [loading, setLoading] = useState(true);
    const { token } = useAuth();

    const fetchNotifications = async () => {
        try {
            const plant_id = localStorage.getItem('plant_id');
            if (!plant_id) {
                throw new Error("Plant ID is missing.");
            }
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/notifications`, {
                params: { plant_id }
            });

            const data: Notification[] = response.data.map((notification: Notification) => {
                const daysLeft = moment(notification.suggested_replacement_date).diff(moment(), 'days');
                const icon = daysLeft < 10 ? (
                    notification.color = '#B91C1C',
                    <WarningFilled style={{ color: '#B91C1C', fontSize: '24px', marginRight: '10px' }} />
                ) : (
                    notification.color = '#FF8B4C',
                    <ExclamationCircleFilled style={{ color: '#FF8B4C', fontSize: '24px', marginRight: '10px' }} />
                );
                return {
                    ...notification,
                    icon,
                };
            });
            setNotifications(data);
        } catch (error) {
            console.error('Failed to fetch notifications:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchNotifications();
    }, []);

    const handleRefresh = () => {
        setLoading(true);
        fetchNotifications();
    };

    const formatDaysFromNow = (date: Date) => {
        const now = moment();
        const end = moment(date);
        const duration = moment.duration(end.diff(now));
        const days = Math.round(duration.asDays());
    
        if (days > 0) {
            return `is due for maintenance in ${days} days`;
        } else if (days < 0) {
            return `is overdue for maintenance by ${Math.abs(days)} days`;
        } else {
            return 'Today';
        }
    };

    return (
        <div>
            {loading ? (
                <Spin size="large" />
            ) : notifications.length === 0 ? (
                <div style={{ textAlign: 'center' }}>
                    <ReloadOutlined 
                        style={{ fontSize: '48px', color: '#1890ff', marginBottom: '16px' }} 
                        onClick={handleRefresh} 
                    />
                    <Typography.Title level={4}>No pumps due for replacement</Typography.Title>
                    <Typography.Text>Tap the icon to refresh the results.</Typography.Text>
                </div>
            ) : (
                <div>
                <Title level={2} style={{ color: '#21456A', marginTop: '0px' }}>Notifications</Title>
                
                <List
                    dataSource={notifications}
                    renderItem={item => (
                        <NotificationItem 
                            id={item.id} 
                            key={item.id} 
                            title={item.pump.name} 
                            description={`${item.pump_part.part_type.name} ${formatDaysFromNow(item.suggested_replacement_date)}`} 
                            icon={item.icon} 
                            color={item.color} 
                            pump={item.pump} 
                            pump_part={item.pump_part} 
                            suggested_replacement_date={item.suggested_replacement_date}
                            pump_id={item.pump_id}
                        />
                    )}
                />
                </div>
            )}
        </div>
    );
};

export default Home;
