import React from 'react';
import { Card, Row, Col, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Notification } from '../utils/types';
import { useNavigate } from 'react-router-dom';

type NotificationItemProps = Omit<Notification, 'replacement_date' | 'message'>;

const NotificationItem: React.FC<NotificationItemProps> = ({ id, title, description, icon, color, pump_id }) => {
  const navigate = useNavigate();

  const handleOnView = (id: number) => {
    navigate(`/tracking/${id}`);
  };

  return (
    <Card 
      style={{ borderColor: color, borderWidth: '1px', margin: '10px 0' }} 
      hoverable 
      onClick={() => handleOnView(pump_id)}
    >
      <Row align="middle" justify="space-between" gutter={[8, 8]}>
        <Col span={22}>
          <Row align="middle" justify="space-between">
            <Col span={3}>
              {icon}
            </Col>
            <Col span={21}>
              <div>
                <Typography.Text strong style={{ color: '#21456A', display: 'block', fontSize: '20px' }}>{title}</Typography.Text>
                <Typography.Text style={{ color: '#21456A', display: 'block', fontSize: '14px' }}>{description}</Typography.Text>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={2}>
          <ArrowRightOutlined style={{ fontSize: '20px', color: '#21456A' }} />
        </Col>
      </Row>
    </Card>
  );
};

export default NotificationItem;
